import React, { useState, useEffect } from 'react'
import { GoogleMap, useJsApiLoader  } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';


const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 44.1641,
    lng: 28.4612
};

const onLoad = marker => {
    console.log('marker: ', marker)
}

const MyMapGoogle = ({ position }) => {


    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCJxZWtYXhbRlfUfdElm0XoxsKBmrmJnrE"
    })

    const [map, setMap] = useState(null)


    useEffect(() => {
        setMap(map)

    }, [map])


    
    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
        >
            
            <Marker
                onLoad={onLoad}
                position={position}
                clickable={true}
                label = {"PIN"}	
                title = {"Nu stiu"}
                draggable ={true}	
                onClick ={e => (console.log(e))}
            />
 
            )
        </GoogleMap>
    ) : <></>
}
export { MyMapGoogle };

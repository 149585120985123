// import './App.css';
import MyComponent from './components/MyComponent';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <MyComponent/>
          </header>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { darken, lighten } from '@mui/material/styles';
import { MyMapGoogle } from './GoogleMap';
import Button from '@mui/material/Button';
import Axios from 'axios';


function MyComponent() {


  const [position, setPosition] = useState({

    lat: 44.1698298318706,
    lng: 28.44708848383940

  }
  );

  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [arrayRow, setArrayRow] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      field: 'id', headerName: 'ID', width: 10,
    },
    { field: 'Masurat', headerName: 'masurat', width: 60, editable: true },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    { field: 'adresa', headerName: 'Adresa', width: 200 },
    { field: 'telefon', headerName: 'Telefon', width: 130 },
    { field: 'disponibilitate', headerName: 'disponibilitate', width: 130 },
    { field: 'detalii', headerName: 'detalii', width: 600 },
    { field: 'coordonate', headerName: 'coordonate', width: 300 },
    { field: 'timp', headerName: 'timp', width: 250 },




  ];

  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


  const getFetch = () => {
    Axios({
      method: "POST",
      url: "https://api.avaprime.ro/login",
      data: {
        email: loginUsername,
        password: loginPassword

      },
      withCredentials: true,
      headers: {
        "access-control-allow-credentials": true,
        "access-control-allow-headers": "Origin, X-Requested-With,Content-Type, Accept",
        "access-control-allow-methods": "GET,PUT,POST,DELETE",
        "Content-Type":"application/json"
      }

    }).then(res => {
      console.log(res.status)

      getDate()
    })
      

  }

  const getLogout = () => {
    Axios({
      method: "GET",
      withCredentials: true,
      url: "https://api.avaprime.ro/users/logout",
    }).then((res) => {
      
      console.log(res.status);
    });
  };


  const getDate = () => {
    Axios({
      method: "GET",
      url: "https://api.avaprime.ro/date",
      withCredentials: true,

    }).then(res => res.data)
      .then(
        (result) => {
          setIsLoaded(true);

          result.forEach(element => {
            setArrayRow(arrayRow => [...arrayRow, {
              id: element.id, lastName: element.nume,
              firstName: element.prenume, adresa: element.adresa, telefon: element.telefon,
              disponibilitate: element.disponibilitate, detalii: element.detalii,
              coordonate: element.coordonate, timp: element.timp,
              Masurat: element.Masurat
            }])



          })

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
          console.log(error);
        }
      )

  }


  


  useEffect(() => {

  }, [])




  return (

    <Box
      sx={{
        height: 300,
        width: 1,
        '& .super-app-theme--Open': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
          },
        },
        '& .super-app-theme--1': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode,
              ),
          },
        },
        '& .super-app-theme--0': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode,
              ),
          },
        },
        '& .super-app-theme--Rejected': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          '&:hover': {
            bgcolor: (theme) =>
              getHoverBackgroundColor(theme.palette.error.main, theme.palette.mode),
          },
        },

      }}
    >
      <div style={{ height: 700, width: '100%' }}>

      <div>
        <input
          placeholder="username"
          onChange={(e) => setLoginUsername(e.target.value)}
        />
        <input
          placeholder="password"
          type="password"

          onChange={(e) => setLoginPassword(e.target.value)}
        />
        <Button variant="contained" size="medium" onClick={getFetch}> LogIn </Button>
     
        <Button variant="outlined" color="error" size="medium" onClick={getLogout}> LogOut </Button>
      </div>

        <DataGrid
          rows={arrayRow}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection
          disableSelectionOnClick
          isRowSelectable={(params) => params.row.Masurat < 1}

          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            console.log(ids)
            const selectedRows = arrayRow.filter((row) =>
              selectedIDs.has(row.id)

            );

            setSelectedRows(selectedRows);
            if ((selectedIDs.size === 0) || (selectedRows[0].coordonate === "")) {
              console.log("not good")
              setPosition(({ lat: 0, lng: 0 }))
            } else {

              let latX = selectedRows[0].coordonate.split(",")[0].replace("(", "");
              let longY = selectedRows[0].coordonate.split(",")[1].replace(")", "");;
              !latX || !longY ? setPosition(({ lat: 44.1641, lng: 28.4612 })) : setPosition(({ lat: Number(latX), lng: Number(longY) }))
              console.log(latX)
            }


          }}
          onRowSelected={
            (e) => console.log(e)
          }
          getRowClassName={(params) =>
            `super-app-theme--${params.getValue(params.id, 'Masurat')}`
          }
          components={{
            Toolbar: GridToolbar,
          }}
          initialState={{
            filter: {
              filterModel: {
                items: [
                  {
                    columnField: 'commodity',
                    operatorValue: 'contains',
                    value: '',
                  },
                ],
              },
            },
          }}

        />

      </div>
      <div style={{ fontSize: 10 }}>
        {JSON.stringify(selectedRows, null, 4)}
        );          </div>
      < >
        {selectedRows.map((elem) => (<h3 key={elem.id}>{elem.coordonate}</h3>))}
        <div></div>
      </>
      <MyMapGoogle position={position} />

    </Box>




  );



}

export default MyComponent;
